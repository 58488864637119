<template>
    <v-content class="page_home" id="accessibilita">
        <Accessibilita_body/>
    </v-content>
</template>

<script>
    import Accessibilita_body from "@/components/Accessibilita_body";
    import {mapGetters,mapActions} from "vuex";

    export default {
        name: "accessibilita",
        computed: {
            ...mapGetters({
                'getCustomerTypeTrk': 'params/getCustomerTypeTrk',
            }),
        },
        components: {
            Accessibilita_body,
        },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        beforeMount(){
            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: this.getCustomerTypeTrk,
                page_name: "Accessibilità",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        head: {
            script: [
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA1,
                },
                {
                    type: 'text/javascript',
                    inner: 'if (typeof tc_events_global === "function") { \n' +
                           '    tc_events_global(this, "virtual_page", {});\n' +
                           '}'
                },
            ],
            noscript: [{
                src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                width: '1',
                height: '1',
                rel: 'noindex,nofollow',
            }],
        },
        methods: {
            ...mapActions({
                'actionClearQuotation': 'params/actionClearQuotation',
                'actionInsType': 'params/actionInsType',
                'actionCustomerType': 'params/actionCustomerType'
            }),
        },
        created() {
            this.actionInsType(null);
            this.actionCustomerType('');
            this.actionClearQuotation();
        },

    };
</script>
